import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';

import { Tos } from '@pages-impl/terms-of-service';

export default function Index(props) {
  return (
    <Layout {...props} oldPage>
      <SEO
        title="Terms of service | CodiLime"
        description="Read our website’s Terms and Conditions of Use."
        noIndex={true}
      />
      <Header title="Terms of service" src="bg.jpg" className="terms-of-service" />
      <Tos />
    </Layout>
  );
}
