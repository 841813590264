import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import { tos } from './tos.module.scss';

const listContent = [
  'Modify or copy the materials;',
  'Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);',
  'Attempt to decompile or reverse engineer any software contained on this website;',
  'Remove any copyright or other proprietary notations from the materials;',
  'Transfer the materials to another person or “mirror” the materials on any other server.',
];

export function Tos() {
  return (
    <>
      <StandardSection id="terms-of-service" title="Terms">
        <div className={tos}>
          <p>
            By accessing this website, which is property of CodiLime (the “Company”), you are agreeing to be bound by
            this website’s Terms and Conditions of Use, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you
            are prohibited from using or accessing this site. The materials contained in this website are protected by
            applicable copyright and trademark law.{' '}
          </p>
          <h2>Use licence</h2>
          <p>
            Permission is granted to temporarily download one copy of the materials (information or software) on this
            website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer
            of title, and under this license you may not:
          </p>
          <ListRender.Ul withArrows blue>
            {listContent.map((data, index) => (
              <ListRender.Li key={index}>{data}</ListRender.Li>
            ))}
          </ListRender.Ul>
          <p>
            This license shall automatically terminate if you violate any of these restrictions and may be terminated by
            the Company at any time. Upon terminating your viewing of these materials or upon the termination of this
            license, you must destroy any downloaded materials in your possession whether in electronic or printed
            format.
          </p>
          <h2>Disclaimer</h2>
          <p>
            The materials on this website are provided “as is”. The Company makes no warranties, expressed or implied,
            and hereby disclaims and negates all other warranties, including without limitation, implied warranties or
            conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights. Further, the Company does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use of the materials on its website or
            otherwise relating to such materials or on any sites linked to this website.
          </p>
          <h2>Limitations</h2>
          <p>
            In no event shall the Company or its suppliers be liable for any damages (including, without limitation,
            damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to
            use the materials on this website, even if the Company or its authorized representative has been notified
            orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations
            on implied warranties, or limitations of liability for consequential or incidental damages, these
            limitations may not apply to you.
          </p>
          <h2>Revisions and errata</h2>
          <p>
            The materials appearing on this website could include technical, typographical, or photographic errors. The
            Company does not warrant that any of the materials on its website are accurate, complete, or current. The
            Company may make changes to the materials contained on its website at any time without notice. The Company
            does not, however, make any commitment to update the materials.
          </p>
          <h2>Links</h2>
          <p>
            The Company has not reviewed all of the sites linked to its website and is not responsible for the contents
            of any such linked site. The inclusion of any link does not imply endorsement by the Company of the site.
            Use of any such linked website is at the user’s own risk.
          </p>
          <h2>Modifications of the website terms of use</h2>
          <p>
            The Company may revise these terms of use for its website at any time without notice. By using this website
            you are agreeing to be bound by the current version of these Terms and Conditions of Use.
          </p>
          <h2>Governing law</h2>
          <p>
            Any claim relating to the Company’s website shall be governed by the laws of Poland without regard to its
            conflict of law provisions.
          </p>
        </div>
      </StandardSection>
    </>
  );
}
